const data = {
  seo: {
    title:
      'Карьера после обучения в Эльбрус Буткемп - поможем найти работу в IT',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Специалисты Эльбрус Буткемп помогут с трудоустройством в IT-индустрии после прохождения курсов. Наши специалисты проконсультируют вас, помогут составить продающее резюме, подберут эффективные инструменты поиска работы, а также морально поддержат, если вы волнуетесь. ',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'Карьера после обучения в Эльбрус Буткемп - поможем найти работу в IT',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Специалисты Эльбрус Буткемп помогут с трудоустройством в IT-индустрии после прохождения курсов. Наши специалисты проконсультируют вас, помогут составить продающее резюме, подберут эффективные инструменты поиска работы, а также морально поддержат, если вы волнуетесь. ',
      },
      {
        property: 'og:image',
        name: null,
        content:
          'https://elbrusboot.camp/static/c96dac2f3168275c372238e862f9cd9f/cbf84/career-desktop.png',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: '@elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content:
          'https://elbrusboot.camp/static/c96dac2f3168275c372238e862f9cd9f/cbf84/career-desktop.png',
      },
    ],
  },
  greetings: {
    location: {
      title: 'бесплатно',
    },
    type: 'при прохождении курса поможем',
    title: ['Найти', 'работу в IT'],
    points: [
      'Проконсультируем по IT-рынку',
      'Поможем составить продающее резюме',
      'Подберем эффективные инструменты поиска работы',
      'Поделимся лайфхаками выпускников',
      'Поддержим, если волнуетесь',
    ],
    action: 'Получить консультацию',
    ymEvents: {
      signUpFormSubmit: 'onlineJSTopSignUp',
      consultationFormSubmit: 'onlineJSTopConsultation',
    },
    buttonLink: '/#program',
  },
  employment: {
    title: 'Трудоустройство',
    subtitle: 'быстрое',
    description:
      'Процент выпускников, нашедших работу в первые 3 месяца после обучения',
    anchor: 'career_employment',
    programs: [
      {
        title: 'Программа Fullstack Javascript',
        city: 'Онлайн',
        percentage: 62,
        students_numbers: '66 из 111 выпускников',
        period: 'за 2-ое полугодие 2022',
      },
      {
        title: 'Программа Fullstack Javascript',
        city: 'Москва',
        percentage: 89,
        students_numbers: '132 из 157 выпускников',
        period: 'за 2-ое полугодие 2022',
      },
      {
        title: 'Программа Fullstack Javascript',
        city: 'Санкт-Петербург',
        percentage: 85,
        students_numbers: '72 из 92 выпускников',
        period: 'за 2-ое полугодие 2022',
      },
      {
        title: 'Программа\nData Science',
        city: 'Онлайн',
        percentage: 79,
        students_numbers: '20 из 28 выпускников',
        period: 'за 2-ое полугодие 2022',
      },
    ],
    statistic: [
      {
        id: 1,
        value: '--',
        description: 'студентов выпущено...',
        link: null,
      },
      {
        id: 2,
        value: '--',
        description: 'студентов находят работу..',
        link: null,
      },
      {
        id: 3,
        value: '--',
        description: 'средняя зарплата после обучения',
        link: null,
      },
      {
        id: 5,
        value: '--',
        description: null,
        link: {
          title: 'Рейтинг Яндекса',
          url: 'https://yandex.ru/search/?lr=213&text=elbrus+bootcamp',
        },
      },
    ],
    research: {
      title: 'Исследование Эльбрус Буткемп',
      content:
        'Статистика по трудоустройству выпускников Эльбрус Буткемп подтвержденная исследованием',
      link: {
        title: 'Читать исследование',
        url: 'https://elbrusbootcamp.online/job-report2024/',
      },
    },
  },
  careerStatements: {
    title: 'Карьерная неделя',
    subtitle: 'как проходит',
    anchor: 'career_week_statements',
    minHeight: '294px',
    minWidth: '320px',
    list: [
      {
        period: 'Понедельник',
        phase: '1 день',
        description: [
          'Определяете цель',
          'Составляете крутое резюме',
          'Выстраиваете стратегию и источники поиска',
          'Пишете и разбираете сопроводительное письмо.',
        ],
        marginTop: '-120px',
        minHeight: '222px',
      },
      {
        period: 'Вторник',
        phase: '2 день',
        description: [
          'Разбираете типовые вопросы на собеседованиях',
          'Тренируетесь проходить собеседование с карьерным коучем',
          'Составляете самопрезентацию и оттачиваете ее',
          'Учитесь принимать офферы и получать отказы.',
        ],
        marginTop: '-129px',
        minHeight: '238px',
      },
      {
        period: 'Среда',
        phase: '3 день',
        description: [
          'Знакомитесь и работаете с понятием синдрома самозванца',
          'Оформляете GitHub',
          'Разбираетесь с хитростями и особенностями поиска работы в LinkedIn.',
        ],
        marginTop: '-102px',
        minHeight: '222px',
      },
      {
        period: 'Четверг',
        phase: '4 день',
        description: [
          'Готовитесь к техническим собеседованиям',
          'Разбираете типовые вопросы и задачи',
          'Участвуете в тренинге по переговорам',
          'Расспрашиваете выпускника о его опыте.',
        ],
        marginTop: '-130px',
        minHeight: '210px',
      },
      {
        period: 'Пятница',
        phase: '5 день',
        description: [
          'Проходите тренировочные технические собеседования, объединяясь в группы между кампусами',
          'Дорабатываете резюме и оформляете профили в социальных сетях.',
        ],
        marginTop: '-144px',
        minHeight: '210px',
      },
    ],
  },
  mentors: {
    subtitle: 'наши',
    title: 'Карьерные коучи',
    mentors: [
      {
        fullName: 'Надежда Крутикова',
        content:
          '5+ лет в IT рекрутменте, 15+ лет руководящих позиций в HR. 400+ карьерных консультаций по переходу в IT',
        position: 'Teamlead и карьерный коуч',
        imgName: 'krutikova',
      },
      {
        fullName: 'Александра Куликова',
        content:
          '5+ лет опыта в карьерном консультировании в IT, 10+ лет опыта HR в международных компаниях. Эксперт по резюме на английском',
        position: 'Карьерный коуч',
        imgName: 'kulikova',
      },
    ],
  },
  graduates: {
    title: 'Отзывы выпускников',
    subtitle: 'реальные',
    data: {
      statistic: [
        {
          id: 5,
          value: null,
          description: null,
          link: {
            title: 'Рейтинг Яндекса',
            url: 'https://yandex.ru/search/?lr=213&text=elbrus+bootcamp',
          },
        },
      ],
      people: [
        {
          video: 'hBmQfIz5axk',
          fullName: 'Игорь Полубарьев',
          position: 'программа Data Science',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/easytosay',
            },
          ],
        },
        {
          video: 'hkTNQSOtLh4',
          fullName: 'Владислав Рудницкий',
          position: 'программа Fullstack Javascript МСК',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/vladislavRud',
            },
          ],
        },
        {
          video: '6E1aQeeKecY',
          fullName: 'Лесли Фернсби',
          position: 'программа Fullstack Javascript Онлайн',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/LeslieFernsby',
            },
          ],
        },
        {
          video: 'gLeiyKWYWm0',
          fullName: 'Антон Мушнин',
          position: 'программа Fullstack Javascript СПБ',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/anton_mushnin',
            },
          ],
        },
      ],
    },
  },
  advantages: {
    title: 'вы получите',
    list: [
      {
        title: 'вы получите',
        subtitle: 'Поддержку во время и после обучения',
        description:
          'Работа с карьерным коучем и знакомство с IT-рынком начинается со второй фазы. Затем вас ждет карьерная неделя и активная разработка стратегии трудоустройства. После карьерной недели работа с коучами не заканчивается — вы всегда можете попросить помощи с доработкой резюме и просто обратиться за поддержкой и советом.',
      },
      {
        subtitle: 'Резюме и стратегию поиска работы',
        description: `Под нашим руководством вы соберете сильное портфолио, выстроите план поиска работы, разберете типовые вопросы, научитесь проходить технические собеседования, принимать офферы и реагировать на отказы. `,
      },
      {
        subtitle: 'Прокачанные soft skills',
        description: [
          'Особенно вам пригодятся: ',
          '— Управление эмоциями при стрессе',
          '— Адаптируемость на новом месте',
          '— Поиск и анализ информации',
          '— Восприятие критики',
          '— Умение работать в команде',
        ],
      },
      {
        subtitle: 'Комьюнити Эльбрус Буткемп',
        description:
          'Благодаря экосистеме чатов выпускники могут общаться, быстро получать техническую помощь и моральную поддержку, а также оставаться на связи из любой точки мира. Также мы регулярно проводим нетворкинг-сессии, делимся лайфхаками для дальнейшего развития навыков и организуем встречи выпускников по всему миру.',
      },
      {
        subtitle: 'Нетворкинг-мероприятия',
        description: `Хакатоны, встречи в формате Random Coffee, масштабные мероприятия в честь важных для Эльбрус событий — это возможность познакомиться с IT-единомышленниками, найти друзей, партнеров для проекта и обменяться опытом с коллегами из сферы.`,
      },
    ],
  },
  faq: {
    title: 'На ваши возможные вопросы',
    subtitle: 'Ответы',
    questions: [
      {
        __component: 'faq.faq',
        id: 1,
        q: 'Вы найдете за меня работу?',
        a: 'Мы даем четкие и эффективные инструменты поиска работы, детально прорабатываем резюме и стратегию прохождения собеседований — это навыки, которые пригодятся нашим выпускникам не только при поиске работы после буткемпа, но и в будущем. Мы с радостью поддерживаем и направляем, а работу выпускники ищут сами, чтобы развить этот полезный навык.',
      },
      {
        __component: 'faq.faq',
        id: 2,
        q: 'Все ли находят работу после окончания буткемпа? Даже те, у кого не было опыта в IT?',
        a: 'Среди наших выпускников много ребят разных профессий без предыдущего опыта в IT, и 93% из них находят работу в течение первых шести месяцев после прохождения буткемпа. Мы не можем гарантировать трудоустройство, но даем эффективную стратегию поиска работы и индивидуально работаем с каждым резюме. При активных откликах каждый выпускник со временем находит работу мечты в сфере разработки.',
      },
      {
        __component: 'faq.faq',
        id: 3,
        q: 'Поможете ли вы составить резюме на английском и найти работу за границей ?',
        a: 'Специально для наших выпускников у нас есть эксклюзивный вебинар по стратегии поиска работы за границей, в котором мы подробно рассказываем, с чего начать, где искать и как выстроить стратегию поиска. Более того, наши карьерные коучи имеют опыт проведения и прохождения собеседований за границей. Наши выпускники успешно устраиваются на работу в США, Канаде, Великобритании и странах Европы. Также у нас есть подробные гайды по составлению резюме на английском, шаблоны и примеры резюме. Мы знаем стандарты международных ассоциаций по составлению резюме и индивидуально обсуждаем каждое резюме и даем рекомендации.',
      },
      {
        __component: 'faq.faq',
        id: 4,
        q: 'Что делать, если у меня не получится найти работу сразу после выпуска или я хочу сначала отдохнуть после обучения?',
        a: 'Мы оказываем поддержку по поиску работы в течение 1 года  после окончания карьерной недели.',
      },
      {
        __component: 'faq.faq',
        id: 5,
        q: 'Сколько времени займет поиск работы? Смогу ли я совмещать его с другими делами?',
        a: 'Поиск работы требует времени, сил и полного сосредоточения вашего внимания на подготовке и прохождении собеседований. 93% наших выпускников находят работу в течение шести месяцев после выпуска.',
      },
      {
        __component: 'faq.faq',
        id: 6,
        q: 'Мне страшно проходить собеседования. Как справиться с волнением?',
        a: 'На карьерной неделе в дружелюбной атмосфере вы потренируетесь проходить все этапы собеседования в IT компании, а наши выпускники, которые уже нашли работу, поддержат советом и настроят на успех.',
      },
      {
        __component: 'faq.faq',
        id: 7,
        q: 'Я не понимаю, как устроено техническое интервью',
        a: 'На карьерной неделе есть отдельный блок с занятиями по технической части, на которых мы подробно показываем специфику технической части и тренируем вопросы и задания с реальных технических собеседований.',
      },
    ],
  },
  questions: {
    message: 'Остались вопросы?',
    actions: {
      reverseButtons: true,
      first: {
        title: 'Записаться на обучение',
      },
      second: {
        title: 'Посмотреть бесплатные мероприятия',
        link: '/events/',
      },
    },
    ymEvents: {
      mainProgrammsBottomClick: 'mainProgrammsBottom',
      consultationFormSubmit: 'mainConsultation',
    },
  },
};

export default data;
